<template>
  <div class="progress-bar">
    <div class="progress-bar-graph">
      <template v-for="(step, idx) in progressData" :key="`item-${idx}`">
        <div v-if="idx == 0" class="spacer"></div>
        <div class="progress-bar-step" :class="{
          'step-done': idx < currentIndex,
          'step-current': idx == currentIndex,
          'step-todo': idx > currentIndex,
          'has-link': !!step.url,
        }" @click="navigate(step)">
          <div class="step-icon"><i v-html="step.icon_svg"></i></div>
        </div>

        <div v-if="idx != Object.keys(progressData).length - 1" :key="`spanner-${idx}`" class="spanner">
          <i class="line" :class="{
            'line-done': idx < currentIndex,
            'line-todo': idx >= currentIndex,
          }"></i>
        </div>
        <div v-if="idx == Object.keys(progressData).length - 1" class="spacer"></div>
      </template>
    </div>
    <div class="progress-bar-labels">
      <template v-for="(step, idx) in progressData" :key="`item-${idx}`">
        <div class="progress-bar-label" :class="{
          'step-done': idx < currentIndex,
          'step-current': idx == currentIndex,
          'step-todo': idx > currentIndex,
          'has-link': !!step.url,
        }" @click="navigate(step)">
          <div class="step-label">
            {{ step.title }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import store from "../store";

export default {
  name: "ProgressBar",
  props: {
    progress: {
      type: String,
      required: true,
    },
  },
  emits: ["navigateToPage"],

  data: () => ({}),

  computed: {
    // a computed getter
    progressData: function () {
      if (typeof this.progress === "string") {
        return JSON.parse(this.progress);
      }
      return this.progress;
    },

    currentIndex: function () {
      const current = this.progressData.findIndex((element) => element.current);
      return current;
    },
  },

  mounted() {
    // hmm, this is a bit fugly: we can determine the previous step here, which is needed
    // in the 'previous' submit button. Since we have it here, let's store in the global store
    // so the submission handler can use it when intercepting the previous click    
    const prevStep = this.currentIndex > 0 ? this.progressData[this.currentIndex - 1] : null;
    store.commit("general/setPrevStep", prevStep);
  },

  methods: {
    navigate(step) {
      if (step.url) {
        this.$emit("navigateToPage", step);

        const event = new CustomEvent("navigateToPage", {
          bubbles: true,
          composed: true,
          detail: {
            getValue: () => {
              return step;
            },
          },
        });
        this.$el.dispatchEvent(event);
      }
    },
  },
};
</script>
<style lang="scss">
@import "../scss/components/_progressbar.scss";
</style>
