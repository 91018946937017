<template>
  <div v-click-outside="hideMenu" class="type-ahead-select">
    <label class="input">
      <input ref="searchBox" v-model="search" type="search" class="selectable" :class="classProps"
        :placeholder="placeholder" aria-label="Search" @input="showSearchItems = true" @click="onClickSelect"
        @keydown.down="onArrowDown" @keydown.up="onArrowUp" @keydown.enter="onEnter" />
      <i class="cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
          <path
            d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
        </svg>
      </i>
    </label>
    <aside v-if="showSearchItems == true" class="select-popout" role="menu" aria-labelledby="menu-heading">
      <ul>
        <li v-for="(item, index) in filteredList" :key="index" tabindex="0" :class="{ active: index + 1 == arrowCounter }"
          @click="selectSearchItem(item); showSearchItems = false;"
          @keydown.enter="selectSearchItem(item); showSearchItems = false;">
          {{ item.name }}
        </li>
        <li v-if="showOther">
          <div class="input-container">
            <input v-model="otherText" type="text" :placeholder="otherLabel" @blur="blurredOtherText"
              @keydown.enter="setOtherText">
            <div class="enter-symbol" @click="setOtherText">➡️</div>
          </div>
        </li>
        <div v-if="emptyResults" class="no-results">{{ noResultsLabel }}</div>
      </ul>
    </aside>
  </div>
</template>

<script>
import { utilities } from "../utils.js";
import vClickOutside from "../directives/v-click-outside.js";

export default {
  name: "AutocompleteWithInput",
  directives: {
    clickOutside: vClickOutside,
  },
  props: {
    inputId: {
      type: String,
      required: true,
    },
    selected: {
      type: String,
      default: null,
    },
    optionsList: {
      type: Array,
      default() {
        return [];
      },
    },
    optionsJson: {
      type: String,
      default: null,
    },
    showOther: {
      type: Boolean,
      default: true,
    },
    otherLabel: {
      type: String,
      default: "Other (type text)"
    },
    noResultsLabel: {
      type: String,
      default: "-- no results found --"
    },
    placeholder: {
      type: String,
      default: "Search here...",
    },
    inputClass: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: { selected: null },
  data() {
    return {
      options: [],
      otherText: "",
      search: "",
      val: "",
      valName: "",
      arrowCounter: 0,
      selectedItem: null,
      showSearchItems: false,
      resettingSearchString: false,
      filterNameActive: false,
    };
  },
  computed: {
    filteredList() {
      if (!this.filterNameActive) {
        return this.options.filter((item) => {
          if (typeof item.show_in_autocomplete === "undefined" || item.show_in_autocomplete === true) {
            return item;
          }
        });
      }

      return this.options.filter((item) => {
        if (this.search) {
          return item.name.toLowerCase().includes(this.search.toLowerCase());
        } else {
          if (typeof item.show_in_autocomplete === "undefined" || item.show_in_autocomplete === true) {
            return item;
          }
        }
      });
    },

    emptyResults() {
      return this.filteredList.length === 0;
    },

    classProps() {
      return [...this.inputClass];
    },
  },

  watch: {
    search: function () {
      this.filterNameActive = true;
      this.arrowCounter = 0;
      if (!this.search) {
        this.selectedValue("");
      }
      // if (!this.resettingSearchString) {
      //   this.selectedValue(this.search);
      // }
    },
  },

  created() {
    if (this.optionsJson) {
      this.options = JSON.parse(this.optionsJson);
    } else {
      this.options = this.optionsList;
    }
  },
  mounted() {
    if (this.selected) {
      // a preselected value is passed in.
      const foundItem = this.options.filter((item) => {
        // check if this item is found in the list
        if (
          this.selected.toLowerCase() === item.name.toLowerCase() ||
          this.selected.toLowerCase() === item.id.toLowerCase()
        ) {
          return true;
        }
      });
      if (foundItem.length > 0) {
        this.selectSearchItem(foundItem[0]);
      } else {
        this.search = this.selected;
        this.otherText = this.selected;
        this.val = this.selected;
      }
    }
  },

  methods: {
    blurredOtherText() {
      this.selectedValue(this.otherText);
    },
    setOtherText(event) {
      if (event) {
        event.preventDefault();  // prevent submission
      }

      if (this.showSearchItems && this.otherText) {
        this.resettingSearchString = true; // do not trigger search change
        this.search = this.otherText;
        this.selectedValue(this.otherText);
        this.showSearchItems = false;
        this.$nextTick(() => {
          this.resettingSearchString = false;
        });
      }
    },
    onClickSelect() {
      this.filterNameActive = false;
      this.showSearchItems = !this.showSearchItems;
    },
    onArrowDown() {
      if (this.arrowCounter < this.filteredList.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter(event) {
      event.preventDefault();  // prevent submission
      if (!this.emptyResults) {
        this.selectSearchItem(this.filteredList[this.arrowCounter - 1]);
      } else {
        this.resettingSearchString = true; // do not trigger search change
        this.search = "";
        this.$nextTick(() => {
          this.resettingSearchString = false;
        });
      }
    },

    selectSearchItem(item) {
      this.$nextTick(() => {
        this.resettingSearchString = true; // do not trigger search change
        this.search = item.name;
        this.selectedItem = item;
        this.showSearchItems = false;
        this.$emit("selected", item);
        this.selectedValue(item.id, item.name);
        this.hideMenu();
        this.$nextTick(() => {
          this.resettingSearchString = false;
        });
      });
    },

    selectedValue(value, name) {
      utilities.emitValueChanged(this.$el, this.inputId, value);
      this.val = value;
      this.valName = name || value;
    },

    hideMenu() {
      if (this.showSearchItems == true) {
        this.showSearchItems = false;
      }
      this.search = this.valName;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../scss/components/_typeaheadselect.scss";

.no-results {
  padding: 0.5rem;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-clear-button {
  cursor: pointer;
}


/* Style the container to position the enter symbol */
.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  /* Adjust the width as needed */
}

/* Style the input field */
.input-container input[type="text"] {
  width: 100%;
  padding-right: 20px;
  /* Space for the enter symbol */
  box-sizing: border-box;
}

/* Style the clickable enter symbol */
.input-container .enter-symbol {
  cursor: pointer;
  margin-left: -30px;
  /* Negative margin to overlap with input */
}
</style>
