<template>
  <div v-if="showPopup" class="popup-overlay">
    <div class="popup-container">
      <div class="popup-content">
        <h2>{{ title }}</h2>
        <p v-html="message"></p>
        <div class="popup-buttons">
          <button @click="handleConfirm" v-html="confirmLabel" :class="confirmCSSClasses"></button>
          <button @click="handleCancel" v-html="cancelLabel"></button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "PopupDialog",
  data() {
    return {
      showPopup: false,
      title: '',
      message: '',
      confirmLabel: '',
      confirmCSSClasses: '',
      cancelLabel: '',
      resolve: null,
      reject: null
    };
  },
  watch: {
    showPopup() {
      this.handleScroll();
    }
  },
  mounted() {
    this.handleScroll();
  },
  methods: {
    openPopup(title, message, confirmLabel, confirmCSSClasses, cancelLabel, cancelCSSClasses) {
      this.title = title;
      this.message = message;
      if(confirmLabel) {
        this.confirmLabel = confirmLabel;
      } else {
        this.confirmLabel = 'OK';
      }
      this.confirmCSSClasses = confirmCSSClasses;
      if(cancelLabel) {
        this.cancelLabel = cancelLabel;
      } else {
        this.cancelLabel = 'Annuleren';
      }
      this.cancelCSSClasses = cancelCSSClasses;
      this.showPopup = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    handleScroll() {
      if (this.showPopup) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    },

    handleConfirm() {
      this.showPopup = false;
      this.resolve(true);
    },
    handleCancel() {
      this.showPopup = false;
      this.resolve(false);
    }
  }
};
</script>
    