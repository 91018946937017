<template>
  <div class="checkboxes-with-info checkbox-controls">
    <div v-for="(input, index) in vocabularyData" :key="`itemInput-${index}`" class="checkbox-control">
      <input :id="`${inputId}-checkbox-${index}`" v-model="checkedData[input.hash]" type="checkbox" />
      <label v-help-info-button="getHelpText(index)" :for="`${inputId}-checkbox-${index}`" class="checkbox"
        data-help-info-button-classes="for-checkbox"><i></i> {{ input.name }}</label>
    </div>
  </div>
</template>

<script>
import { utilities } from "../utils.js";
import vHelpInfoButton from "../directives/v-help-info-button.js"

export default {
  name: "CheckboxesWithInfo",
  directives: {
    HelpInfoButton: vHelpInfoButton,
  },
  props: {
    inputId: {
      type: String,
      required: true,
    },
    value: {
      type: String, // json
      default: null,
    },
    vocabulary: {
      type: String, // json
      required: true,
    },
  },
  emits: ["change"],

  data: () => ({
    vocabularyData: [],
    checkedData: {},
  }),

  watch: {
    checkedData: {
      handler(newValue) {
        if (newValue) {
          let rawValues = Object.keys(newValue).filter((k) => newValue[k]);
          if (rawValues.length === 0) {
            rawValues = null;
          }

          const val = rawValues === null ? "" : JSON.stringify(rawValues);

          this.$emit("change", val);
          utilities.emitValueChanged(this.$el, this.inputId, val);
        }
      },
      deep: true,
    },
  },

  mounted() {
    let valueAsrray = [];
    if (this.value) {
      valueAsrray = JSON.parse(this.value) || [];
    }

    this.vocabularyData = JSON.parse(this.vocabulary);
    for (let vocabItem of this.vocabularyData) {
      this.checkedData[vocabItem.hash] =
        valueAsrray.indexOf(vocabItem.hash) !== -1;
    }
  },

  methods: {
    getHelpText(index) {
      const list_items = this.vocabularyData[index].equipment.map(obj => `<li>${obj.name}</li>`).join("");
      return `<ul>${list_items}</ul>`;
    },
  },
};
</script>
