<template>
  <div class="image-list">
    <div class="image add-images">
      <label :class="{
        'not-allowed': maxImagesSelected,
        allowed: !maxImagesSelected,
      }" class="placeholder-image">
        <i v-if="!maxImagesSelected"></i>
        <i v-if="maxImagesSelected" class="max-images"></i>

        <div class="text">
          <span v-if="!maxImagesSelected">{{ label }}
            <span style="white-space: nowrap">(max: {{ maxImages }})</span></span>
          <span v-if="maxImagesSelected" class="forbidden">Maximaal aantal
            <span style="white-space: nowrap">foto's bereikt</span></span>
        </div>
        <p v-if="placeholder" class="extra-text">
          {{ placeholder }}
        </p>

        <input :id="`multi-image-upload-${inputId}`" ref="fileInput" class="hidden" type="file"
          accept="image/png, image/jpeg" multiple :disabled="maxImagesSelected" title="" @change="onFileChange" />
      </label>
    </div>

    <template v-for="(image, key) in images" :key="key">
      <div class="image">
        <img v-load-image class="inspection-image" :data-key="key" :alt="image.name" :data-src="image.data" />

        <div v-if="image.isUploading" class="spinner-wrapper">
          <i class="spinner">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M256 0c-17.7 0-32 14.3-32 32s14.3 32 32 32V0zM422.3 352c-8.9 15.3-3.6 34.9 11.7 43.7s34.9 3.6 43.7-11.7L422.3 352zM256 64c106 0 192 86 192 192h64C512 114.6 397.4 0 256 0V64zM448 256c0 35-9.4 67.8-25.7 96L477.7 384c21.8-37.7 34.3-81.5 34.3-128H448z" />
            </svg>
          </i>
        </div>

        <div v-if="image.url" class="remove" @click.stop="removeImage(key)">
          <div class="button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
            </svg>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { utilities } from "../utils.js";
import { UploadedImage } from "../models/uploaded-image.js";
import axios from "axios";
import vLoadImage from "../directives/v-load-image.js";
import globalData from '../globaldata.js';

export default {
  name: "MultiImageUpload",
  directives: {
    loadImage: vLoadImage,
  },
  props: {
    inputId: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
    imagesUrl: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Select a photo",
    },
    value: {
      type: String, // json
      default: null,
    },
    useHashForKey: {
      type: Boolean,
      default: false,
    },
    maxImages: {
      type: Number,
      default: 25,
    },
  },
  emits: ["change"],

  data: () => ({
    images: {}, // contains UploadedImage objects
  }),

  computed: {
    maxImagesSelected() {
      return Object.keys(this.images).length >= this.maxImages;
    },
  },

  watch: {
    images: {
      handler() {
        const val = this.getEmitPayload();
        this.$emit("change", val);
        utilities.emitValueChanged(this.$el, this.inputId, val);
        this.uploadImages();
      },
      deep: true,
    },
  },

  mounted() {
    let parsedValue = {};
    if (this.value) {
      parsedValue = JSON.parse(this.value) || {};

      const new_images = {};
      for (let [img_id, img_val] of Object.entries(parsedValue)) {
        if (img_val["url"]) {
          const img = new UploadedImage(img_id, img_val["url"]);
          img.url = img_val["url"];
          new_images[img_id] = img;
        }
      }
      this.images = new_images;
    }
  },

  methods: {

    getEmitPayload() {
      if (this.images && Object.keys(this.images).length > 0) {
        const newValues = {};
        for (let key in this.images) {
          const uploadedImage = this.images[key];
          newValues[uploadedImage.key] = uploadedImage.payloadForInput;
        }
        return JSON.stringify(newValues);
      }
      return null;
    },

    uploadImages() {
      const url = utilities.fix_url_double_slashes(
        `${import.meta.env.VITE_APP_CLIENT_GATEWAY_URL}${this.imagesUrl}`
      );

      const clonedImages = { ...this.images };

      Object.values(clonedImages)

        .filter((img) => !!img)
        .filter((img) => !img.url)
        .filter((img) => !!img.uploadPayload.data)
        .forEach((img) => {
          if (!img.isUploading) {
            img.isUploading = true;

            const payload = {
              ...img.uploadPayload,
              csrfmiddlewaretoken: this.csrfToken,
            };

            axios.post(url, payload, {
              cancelToken: globalData.cancelToken.token,
            }).then((response) => {
              img.name = response["data"]["id"];
              img.url = response["data"]["url"];
              img.isUploading = false;
              // TODO:
              // - progress
              // - get generated filename + replace src
            }).catch((error) => {
              if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
                this.deleteImageFromMemory(img.key);
              } else {
                console.log('Error', error.message);
              }
            });
          }
        });
    },

    deleteImageFromMemory(key) {
      const clonedImages = { ...this.images }
      delete clonedImages[key];
      this.images = clonedImages;
      this.$refs["fileInput"]["value"] = null;
    },

    removeImage(key) {
      const img = this.images[key];
      if (img.url) {
        const url = utilities.fix_url_double_slashes(
          `${import.meta.env.VITE_APP_CLIENT_GATEWAY_URL}${this.imagesUrl}`
        );

        axios.delete(url, { data: img.removePayload }).then(() => {
          this.deleteImageFromMemory(key);
        });
      } else {
        this.deleteImageFromMemory(key);
      }
    },

    onFileChange(e) {
      let readers = [];
      for (const selectedFile of e.target.files) {
        let reader = new FileReader();
        readers.push(reader);
        reader.onload = (e) => {
          if (Object.keys(this.images).length >= this.maxImages) {
            // stop processing the other readers
            for (const currentReader of readers) {
              currentReader.abort();
            }
            return; // do not add this image
          }

          const imageData = e.target.result;
          const img = new UploadedImage(selectedFile.name, imageData);
          this.images[img.key] = img;
        };
        reader.readAsDataURL(selectedFile);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/components/_multiimageupload.scss";
</style>
